import React from "react";
import { Stack, Typography } from "@mui/material";

function Industrial() {
  return (
    <Stack sx={{ m: -3, p: 4, background: "#f4f4f4", color: "#000" }}>
      <Typography
        fontWeight="bold"
        sx={{ fontSize: { md: "2.125rem", xs: "1.825rem" } }}
      >
        Industrial Automation
      </Typography>
      <br />
      <p>
        <Typography variant="body">
          {" "}
          Ancient Engineering in the field of Process Control are capable
          designing different control system to customize for your industrial
          application for example:
        </Typography>
        <Stack sx={{ p: 2 }}>
          <ul>
            <li>Temperature control e.g. furnaces and boilers</li>
            <li> Pressure control system e.g. for boilers and furnaces</li>
            <li>
              {" "}
              Humidity and moisture control system e.g. maize mill plant{" "}
            </li>
          </ul>
        </Stack>
        <Typography variant="body">
          Some of our services include the following:
        </Typography>
        <Stack sx={{ p: 2 }}>
          <ul>
            <li>PLC programming and servicing</li>
            <li> Drive installation, programming, repairs and servicing</li>
            <li> System Integration</li>
            <li> Service contracts </li>
            <li> SCADA software </li>
            <li> Thermography </li>
            <li> Panel boards</li>
          </ul>
        </Stack>
        <Typography variant="body">
          Ancient engineering solutions are suitable for a wide array of basic
          and complex applications; our custom engineered solutions offer
          enhanced precision, repeatability, and ruggedness. We offer domain
          knowledge and technology resources, along with a close working
          relationship, to develop and deliver cost-effective, individually
          tailored solutions. Whether clean slate development or simple
          modifications to an existing design are needed, our expertly
          engineered solutions help to meet the most stringent requirements with
          world class product designs, technology integration, and
          customer-specific manufacturing We have a well-qualified Engineers and
          Technician who specializes in repairs of the following:
        </Typography>
        <Stack sx={{ p: 2 }}>
          <ul>
            <li>Calibration</li>
            <li> Control Cards</li>
            <li> Power Supply Units</li>
            <li> Variable Speed Drives of any model </li>
            <li> Electronic Cards </li>
            <li> Electrical Machines</li>
            <li> Industrial troubleshooting including Root-Cause-Analysis</li>
          </ul>
        </Stack>
        <Typography variant="body">
          We also focus on designing electronic circuits to suit your
          application
        </Typography>
      </p>
    </Stack>
  );
}

export default Industrial;
