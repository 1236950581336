import React from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "./Grid";
import images from "../constants/images";

const ServiceCard = ({ imgUrl, title, subtitle }) => {
  return (
    <Stack gap={2}>
      <Stack
        sx={{
          width: "100%",
          height: "340px",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <img
          src={imgUrl}
          alt=""
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
        />
      </Stack>
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", borderBottom: "1px solid #999", pb: 3 }}
      >
        {title}
      </Typography>
      <Typography variant="body">{subtitle}</Typography>
    </Stack>
  );
};

function ServiceTitle() {
  return (
    <Stack sx={{ mx: { md: 8, xs: 4 }, mt: 8, gap: 8 }}>
      <Typography
        sx={{ fontSize: { md: "2.125rem", xs: "1.825rem" } }}
        fontWeight="bold"
        textAlign="center"
      >
        OUR SERVICES
      </Typography>
      <Stack gap={4}>
        <Grid>
          <ServiceCard
            imgUrl={images.e1}
            title="INDUSTRIAL AUTOMATION"
            subtitle="  Ancient Engineering in the field of Process Control are capable
            designing different control system to customize for your
            industrial application."
          />
          <ServiceCard
            imgUrl={images.e6}
            title="GREEN ENERGY"
            subtitle="Ancient Engineering Designs & Engineers Solar PV Solutions to meet the requirements of its customers. From a total system supply point of view."
          />
        </Grid>
        <Grid>
          <ServiceCard
            imgUrl={images.fa}
            title="FABRICATION"
            subtitle="Ancient Engineering forms part of the engineering industry concentrating on on-site & off-site development, repair work and maintenance contracts."
          />
          <ServiceCard
            imgUrl={images.it}
            title="TECHNICAL SERVICES"
            subtitle="Ancient is committed to the relentless pursuit of excellence in offering complete technical solutions and wide spectrum services that embody the very best in industry."
          />
        </Grid>
      </Stack>
    </Stack>
  );
}

export default ServiceTitle;
