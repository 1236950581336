import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import { MdEmail, MdLocationPin, MdPhone } from "react-icons/md";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Stack sx={{ background: "#000", color: "#555", py: 4 }}>
      <Stack
        sx={{
          borderBottom: "1px solid #555",
          m: 4,
          gap: 2,
          pb: 2,
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <MdLocationPin
            style={{ width: "20px", height: "20px", color: "green" }}
          />
          <Typography variant="body2">
            481 Empowerment Way,Willovale Harare
          </Typography>
        </Box>
        <Box sx={{ width: "1px", height: "auto", background: "#555" }} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <MdPhone style={{ width: "20px", height: "20px", color: "green" }} />
          <Typography variant="body2">
          +263 772 960 955 / +263 712 074 753
          </Typography>
        </Box>
        <Box sx={{ width: "1px", height: "auto", background: "#555" }} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <MdEmail style={{ width: "20px", height: "20px", color: "green" }} />
          <Typography variant="body2">info@ancientengineering.co.zw</Typography>
        </Box>
      </Stack>
      <Stack
        sx={{
          display: "grid",
          gap: 3,
          px: 4,
          gridTemplateColumns: { md: "repeat(4,1fr)", xs: "1fr" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            About Us
          </Typography>
          <Typography variant="body2">
            Ancient Engineering is committed to always provide optimum solutions
            no matter how complex the task is. Our staff engage in continuous
            improvement in their various field of expertise to pace up with any
            technological advancement. We benchmark our operations with best
            performing countries regionally and globally.
          </Typography>
        </Box>
        <Stack gap={2}>
          <Typography variant="h6" fontWeight="bold">
            Our Solutions
          </Typography>
          <Stack gap={1}>
            <Link to="/">
              <Typography variant="body2">- Green Energy</Typography>
            </Link>
            <Link to="/">
              <Typography variant="body2">- Industrial Automation</Typography>
            </Link>
            <Link to="/">
              <Typography variant="body2">- Fabrication</Typography>
            </Link>
            <Link to="/">
              <Typography variant="body2">- Technical Services</Typography>
            </Link>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h6" fontWeight="bold">
            Quick Links
          </Typography>
          <Stack gap={1}>
            <Link to="/">
              <Typography variant="body2">- About Us</Typography>
            </Link>
            <Link to="/">
              <Typography variant="body2">- Home</Typography>
            </Link>
            <Link to="/">
              <Typography variant="body2">- Products</Typography>
            </Link>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ borderTop: "1px solid #555", mx: 4, mt: 4, py: 1, px: 6 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: { md: "space-between", xs: "center" },
            flexDirection: { md: "row", xs: "column" },
            alignItems: "center",
          }}
        >
          <Typography variant="caption">
            Copyright © 2023 Ancient. All Rights Reserved.
          </Typography>
          <Typography variant="caption">
            Developed by -{" "}
            <a href="https://techdv.co.zw/" target="_blank">
              Tech Dzevanhu
            </a>
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}

export default Footer;
