import React from "react";
import { Stack, Typography } from "@mui/material";

function Green() {
  return (
    <Stack sx={{ m: -3, p: 4, background: "#f4f4f4", color: "#000" }}>
      <Typography
        sx={{
          fontSize: { md: "2.125rem", xs: "1.825rem", fontWeight: "bold" },
        }}
      >
        Green Energy
      </Typography>
      <br />
      <p>
        Ancient Engineering Designs & Engineers Solar PV Solutions to meet the
        requirements of its customers. From a total system supply point of view,
        we cover the following areas:
      </p>
      <ul>
        <li>Small residential affordable housing systems</li>
        <li>Medium and large residential systems</li>
        <li>
          Industrial and commercial systems of all sizes from small offices to
          large factories and commercial properties
        </li>
        <li>Residential estates and eco estates</li>
        <li>Agricultural buildings and farms</li>
        <li>Solar fields of all sizes, plant finance options are available</li>
        <br />
        <p>
          We also continuously monitor technological advances in the fast‐moving
          solar industry to bring to our customers, tomorrow’s technology today.
          Ancient company is perfectly equipped to design, manufacture install
          and support solar PV projects of any size, our engineers have
          introduced policies and procedures to ensure all the required
          international and local standards are met wherever an installation is
          located.
        </p>
        <br />
        <p>
          Ancient Engineering offers a number of services in terms of
          Installation and commissioning of solar systems. We pride ourselves
          with installations that are backed by the high‐level engineering and
          design conducted for the clients. Our vision is to install and
          commission a system that exceeds the expectation of the end customer.
          Ancient has several reference sites completed to date* and continues
          to grow. Services are, but not limited to:
        </p>
        <li>Design & Manufacture of Balance of System</li>
        <li>Installation Services</li>
        <li>Site Management and Supervision</li>
        <li>Project Management</li>
        <li>Commissioning</li>
        <li>Reference information available on request</li>
      </ul>
    </Stack>
  );
}

export default Green;
