import React from "react";
import { Hero } from "../../container";
import images from "../../constants/images";
import "./about.css";
import AboutUs from "../../components/AboutUs";
import AboutAccord from "../../components/AboutAccord";
import { Stack } from "@mui/material";

function About() {
  return (
    <>
      <div className="app__about">
        <Hero image={images.about1} title="About" />
        <AboutUs />
        <AboutAccord />
        <div className="app__about-statement"></div>
        <Stack
          sx={{
            flexDirection: { md: "row", xs: "column" },
            gap: 2,
            mx: { md: 8, xs: 4 },
            my: 4,
            color: "#fff",
          }}
        >
          <div className="app__about-values_health">
            <h2>Health, Safety And Environment</h2>
            <br />
            <Stack sx={{ mx: 2 }}>
              <ul>
                <li>
                  Ancient Engineering safety and health department monitors and
                  controls all project sites to ensure safer working principles
                  are adhered.
                </li>
                <li>
                  Ancient Engineering fully implements a standardized Project
                  Safety Program for all projects.
                </li>
                <li>
                  Ancient Engineering is fully committed to adhere and conform
                  to ISO standards.
                </li>
              </ul>
            </Stack>
          </div>
          <div className="app__about-values_quality">
            <h2>Quality Management</h2>
            <br />
            <Stack sx={{ mx: 2 }}>
              <ul>
                <li>
                  Ancient is committed to provide quality services and products
                  to their clients and customer though the use of a lean six
                  sigma management approach.
                </li>
                <li>
                  Ancient intends to offer the best quality in terms of zero
                  defects, quick delivery times and instant project execution.
                  ATS does not compromise on quality and highly values the voice
                  of the customer for improvement and to remain relevant to our
                  customers.
                </li>
                <li>
                  Ancient believes in a continuous quality improvement
                  management strategy to ensure that at all times we can meet
                  the ever changing needs of our customers and become the market
                  leader.
                </li>
              </ul>
            </Stack>
          </div>
        </Stack>
      </div>
    </>
  );
}

export default About;
