import shop1 from "../assets/3kva.jpg";
import shop2 from "../assets/5kva.jpg";
import shop3 from "../assets/10kva.png";
import shop4 from "../assets/vitro.png";
import solar1 from "../assets/solar.jpg";
import solar2 from "../assets/SOLARae.png";
import shop5 from "../assets/hybrid.jpg";
import contact from "../assets/contact.jpg";
import contact2 from "../assets/contact21.jpg";
import helmet from "../assets/helmet.jpg";
import home from "../assets/home.jpg";
import services from "../assets/services.jpg";
import about1 from "../assets/about1.jpg";
import about2 from "../assets/about2.jpg";
import e1 from "../assets/e1.jpeg";
import e6 from "../assets/e6.jpeg";
import it from "../assets/it.jpeg";
import why from "../assets/why-us.png";
import logo from "../assets/logo.png";
import idea from "../assets/idea.svg";
import factory from "../assets/factory.svg";
import goals from "../assets/goals.svg";
import stock from "../assets/stock.svg";
import vision from "../assets/vision.svg";
import generator from "../assets/generator.png";
import waterpump from "../assets/water-pump.png";
import business from "../assets/business.svg";
import hero from "../assets/hero.png";
import tables from "../assets/tables.png";
import fa from "../assets/fa.png";
import anc from "../assets/anc.jpg";
import anc1 from "../assets/anc1.jpg";
import anc2 from "../assets/anc2.jpg";
import desk from "../assets/desk.jpg";
import gate from "../assets/gate.jpg";
import flower from "../assets/flower.jpg";
import table from "../assets/table.jpg";
import truck from "../assets/truck.jpg";
import solar3 from "../assets/solar2.jpg";
import roof from "../assets/roof.jpg";

export default {
  tables,
  anc,
  anc1,
  anc2,
  desk,
  gate,
  flower,
  table,
  truck,
  solar3,
  roof,
  e6,
  e1,
  it,
  fa,
  hero,
  services,
  shop1,
  shop2,
  shop3,
  shop4,
  shop5,
  solar1,
  solar2,
  contact,
  contact2,
  helmet,
  home,
  about1,
  about2,
  why,
  logo,
  idea,
  factory,
  goals,
  vision,
  stock,
  generator,
  waterpump,
  business,
};
