import React from "react";
import { Route, Routes } from "react-router-dom";
import "./app.css";
import { Home, About, Services, Shop } from "./screens";
import Contact from "./screens/Contact";
import Error from "./screens/Error";
import Layout from "./components/Layout";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/services"
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
        <Route
          path="/products"
          element={
            <Layout>
              <Shop />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
