import React from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "./Grid";

function Solutions() {
  return (
    <Stack sx={{ height: "300px", px: { md: 2, xs: 4 }, mt: { md: 0, xs: 8 } }}>
      <Grid>
        <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: { md: "2.125rem", xs: "1.425rem", fontWeight: "bold" },
            }}
          >
            SERVICES & SOLUTIONS
          </Typography>
        </Stack>
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {/* <Box sx={{ width: "1px", height: "auto", background: "#000" }} /> */}
          <Typography variant="body">
            Ancient Engineering is committed to always provide optimum solutions
            no matter how complex the task is. Our staff engage in continuous
            improvement in their various field of expertise to pace up with any
            technological advancement. We benchmark our operations with best
            performing countries regionally and globally. Ancient uses the best
            business practice to meet and exceed customer requirements. Ancient
            is made up of a team of young and skilled personnel who are very
            innovative and quickly adapts to the ever changing technology.
          </Typography>
        </Stack>
      </Grid>
    </Stack>
  );
}

export default Solutions;
