import React from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { Hero } from "../../container";
import { MdOutlineEmail } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import { SlPhone } from "react-icons/sl";

function Contact() {
  return (
    <Stack>
      <Hero title="Contact Us" />
      <Stack
        sx={{
          mx: { md: 8, xs: 4 },
          my: 8,
          flexDirection: {
            md: "row",
            xs: "column",
          },
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Stack sx={{ justifyContent: "center", alignItems: "center", gap: 4 }}>
          <GrMapLocation style={{ width: "60px", height: "60px" }} />
          <Typography variant="h6" color="green" fontWeight="bold">
            LOCATION
          </Typography>
          <Typography color="#555" variant="body">
            481 Empowerment Way,Willovale Harare
          </Typography>
        </Stack>
        <Stack sx={{ justifyContent: "center", alignItems: "center", gap: 4 }}>
          <SlPhone style={{ width: "60px", height: "60px" }} />
          <Typography variant="h6" color="green" fontWeight="bold">
            PHONE
          </Typography>
          <Typography color="#555" variant="body">
            +263 772 960 955 / +263 712 074 753
          </Typography>
        </Stack>
        <Stack sx={{ justifyContent: "center", alignItems: "center", gap: 4 }}>
          <MdOutlineEmail style={{ width: "60px", height: "60px" }} />
          <Typography variant="h6" color="green" fontWeight="bold">
            EMAIL
          </Typography>
          <Typography color="#555" variant="body">
            info@ancientengineering.co.zw
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{ justifyContent: "center", alignItems: "center", my: 4, gap: 4 }}
      >
        <Typography
          color="green"
          fontWeight="bold"
          fontSize={{ md: "2.125rem", xs: "1.425rem" }}
        >
          LEAVE US YOUR INFO
        </Typography>
        <Typography variant="body" color="#333" letterSpacing="2">
          and we will get back to you.
        </Typography>
        <form>
          <Stack gap={2}>
            <Stack sx={{ flexDirection: { md: "row", xs: "column", gap: 4 } }}>
              <TextField placeholder="Full Name" />
              <TextField placeholder="Email Address" type="email" />
            </Stack>
            <TextField placeholder="Website" />
            <textarea
              placeholder="Comments"
              rows={4}
              style={{ padding: 8, width: "100%", resize: "none" }}
            />

            <input
              type="submit"
              value="Send Message"
              className="custom__button"
            />
          </Stack>
        </form>
      </Stack>
      <Stack>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3797.040868346875!2d30.958844749496276!3d-17.883542487723066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a13450e8b7f5%3A0xcadf9bc4fb75641d!2s481%20Empowerment%20Way%2C%20Harare!5e0!3m2!1sen!2szw!4v1674740927163!5m2!1sen!2szw"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Stack>
    </Stack>
  );
}

export default Contact;
