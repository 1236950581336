import React from "react";
import { Stack, Typography } from "@mui/material";
import { MdPlayCircle } from "react-icons/md";
import { Link } from "react-router-dom";

function Project() {
  return (
    <Stack
      sx={{
        // background: "#0093d9",
        background: "#000",
        color: "#fff",
        height: "180px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          px: { md: 8, xs: 4 },
          justifyContent: "space-evenly",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography
          fontWeight="bold"
          sx={{ fontSize: { md: "2.125rem", xs: "1.1rem" } }}
        >
          Start a project with us today
        </Typography>
        <Link to="/contact">
          {" "}
          <MdPlayCircle style={{ width: "40px", height: "40px" }} />
        </Link>
      </Stack>
    </Stack>
  );
}

export default Project;
