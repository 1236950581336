import React from "react";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import error from "../../assets/error.png";

function index() {
  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
        background: "#0093d9",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Stack>
        <img src={error} alt="" sx={{ width: "200px", height: "200px" }} />
      </Stack>
      <Link to="/">
        <Typography variant="body">Go Back to Homepage</Typography>
      </Link>
    </Stack>
  );
}

export default index;
