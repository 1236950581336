import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsTools } from "react-icons/bs";
import images from "../../constants/images";
import { Link } from "react-router-dom";
import "./navbar.css";

import { Typography, Stack, Box } from "@mui/material";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <Stack>
      <Stack
        sx={{ flexDirection: "row", justifyContent: "space-between", m: 2 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Stack
            sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="app__navbar-logo">
              <Link to="/">
                <img src={images.logo} alt="app__logo" />
              </Link>
            </div>
          </Stack>
          <Box>
            <Typography variant="caption" fontWeight="bold" color="#555">
              481 Empowerment Way,Willovale Harare
            </Typography>
          </Box>
        </Box>

        <Box
          className="app__navbar-login"
          sx={{ display: { md: "flex", sm: "none", xs: "none" } }}
        >
          <Link to="/contact">
            <Typography variant="body2">REQUEST A QUOTE</Typography>
          </Link>
        </Box>
        <div className="app__navbar-smallscreen">
          <GiHamburgerMenu
            color="#0093d9"
            fontSize={27}
            onClick={() => setToggleMenu(true)}
          />
          {toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <BsTools
                color="#fff"
                fontSize={27}
                className="overlay__close"
                onClick={() => setToggleMenu(false)}
              />
              <Stack justifyContent="center" alignItems="center">
                <ul className="app__navbar-smallscreen_links">
                  <li>
                    <Link to="/" onClick={() => setToggleMenu(false)}>
                      <Typography>Home</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={() => setToggleMenu(false)}>
                      <Typography>About</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" onClick={() => setToggleMenu(false)}>
                      <Typography>Services</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/products" onClick={() => setToggleMenu(false)}>
                      <Typography>Products</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={() => setToggleMenu(false)}>
                      <Typography>Contact</Typography>
                    </Link>
                  </li>
                </ul>
                <div className="app__navbar-smallscreen-login">
                  <Link to="/contact">
                    <Typography variant="caption">REQUEST</Typography>
                  </Link>
                </div>
              </Stack>
            </div>
          )}
        </div>
      </Stack>
      <nav className="app__navbar">
        <ul className="app__navbar-links">
          <li className="p__opensans">
            <Link to="/">Home</Link>
          </li>
          <li className="p__opensans">
            <Link to="/about">About</Link>
          </li>
          <li className="p__opensans">
            <Link to="/services">Services</Link>
          </li>
          <li className="p__opensans">
            <Link to="/products">Products</Link>
          </li>
          <li className="p__opensans">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </Stack>
  );
};

export default Navbar;
