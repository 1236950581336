import React from "react";
import { Hero } from "../../container";
import images from "../../constants/images";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./shop.css";
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Shop() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Items = ({ imgUrl, heading, title }) => {
    return (
      <Box className="app__shop-items">
        <img src={imgUrl} alt="" />
        <div className="app__shops-items_content">
          <h2>{heading}</h2>
          <p>{title}</p>
          <Link to="/contact">
            <button className="shop__button">Enquire</button>
          </Link>
        </div>
      </Box>
    );
  };

  return (
    <>
      <div className="app__shop">
        <Hero image={images.about2} title="Our Products" />
        <div className="app__shop-content">
          <div className="app__shop-content_display">
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  TabIndicatorProps={{ sx: { background: "#0093d9" } }}
                  sx={{
                    "& button": { borderRadius: "10px  10px 0 0" },
                    "& button.Mui-selected": {
                      backgroundColor: "#0093d9",
                      color: "#fff",
                    },
                  }}
                >
                  <Tab label="Inveters" {...a11yProps(0)} />
                  <Tab label="Solar Panels" {...a11yProps(1)} />
                  <Tab label="Generators" {...a11yProps(2)} />
                  <Tab label="Pumps" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Items
                  imgUrl={images.shop1}
                  heading="Hybrid Inveter"
                  title="We stock a wide range of solar power inverters, including Hybrid Inverters, to complete your PV project!"
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Items
                  imgUrl={images.solar2}
                  heading="Solar Panels"
                  title="Solar technologies convert sunlight into electrical energy either through photovoltaic (PV) panels or through mirrors that concentrate solar radiation."
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Items
                  imgUrl={images.generator}
                  heading="Generator"
                  title="Electrical Backup Generators. Petrol & Diesel Backup Generators. Ideal for onsite and unexpected power outages such as load shedding"
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Items
                  imgUrl={images.waterpump}
                  heading="Pumps"
                  title="Pumps are classified into two types namely Dynamic pumps as well as Positive Displacement Pumps."
                />
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default Shop;
