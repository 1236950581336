import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import Grid from "./Grid";
import { MdEngineering } from "react-icons/md";
import { BsBuilding } from "react-icons/bs";
import { SlEnergy } from "react-icons/sl";
import { GrTechnology } from "react-icons/gr";
import { Link } from "react-router-dom";

const ServiceCard = ({ title, subtitle, icon }) => {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        py: 2,
        "&:hover": { background: "green", color: "#fff", borderRadius: "3px" },
      }}
    >
      {icon}
      <Typography variant="h6" fontWeight="bold" textAlign="center">
        {title}
      </Typography>
      <Typography variant="caption" textAlign="center">
        {subtitle}
      </Typography>
      {/* <CommonButton /> */}
      <Link to="/services">
        <Button>
          <Typography fontWeight="bold">Read More</Typography>
        </Button>
      </Link>
    </Stack>
  );
};

function Service() {
  return (
    <Stack sx={{ mx: 4, gap: 4, mt: { md: 0, sm: 8, xs: 14 } }}>
      <Grid>
        <ServiceCard
          icon={<MdEngineering style={{ width: "80px", height: "80px" }} />}
          title="Industrial Automation"
          subtitle="Temperature Control, Pressure Control, PLC programming and servicing"
        />
        <ServiceCard
          icon={<SlEnergy style={{ width: "80px", height: "80px" }} />}
          title="Green Energy"
          subtitle="Housing Fields, Solar Fields"
        />
      </Grid>
      <Grid>
        <ServiceCard
          icon={<BsBuilding style={{ width: "80px", height: "80px" }} />}
          title="Fabrication, Refractory & Mechanical"
          subtitle="Pipe Installation/Fabrication, Steel Installation/Fabrication, Laser Alignment"
        />
        <ServiceCard
          icon={<GrTechnology style={{ width: "80px", height: "80px" }} />}
          title="Technical Services, ICT & Security"
          subtitle="Electrical Equipment, Security System Technology"
        />
      </Grid>
    </Stack>
  );
}

export default Service;
