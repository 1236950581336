import React from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "./Grid";
import { FaPhoneAlt } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import images from "../constants/images";

function AboutUs() {
  return (
    <Stack
      sx={{
        mt: 4,
        mb: { md: 8, sm: 40, xs: 30 },
        height: "auto",
        mx: { md: 8, xs: 4 },
      }}
    >
      <Grid>
        <Stack gap={3}>
          <Typography variant="h6" fontWeight="bold" color="#0093d9">
            WHO WE ARE
          </Typography>
          <Typography variant="body">
            Ancient Engineering is a Zimbabwean owned company that was
            established to provide innovative solutions in construction,
            engineering, technical services and supply of quality products.
            Ancient was established to contribute positively in the value chain
            by providing agile and affordable services and products
          </Typography>
          <Typography variant="body">
            Ancient is not only committed to provide affordable services, but
            also to be a leading supplier and service provider in terms of
            delivery reliability, quality standards, commitment and excellent
            relationship with customers.
          </Typography>
          <Stack direction="row">
            <Stack
              sx={{
                background: "#000",
                width: "100px",
                height: "100px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  border: "4px solid #fff",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaPhoneAlt style={{ color: "#fff" }} />
              </Stack>
            </Stack>
            <Stack
              sx={{
                background: "#0093d9",
                width: "auto",
                height: "auto",
                justifyContent: "center",
                alignItems: "flex-start",
                px: 4,
                py: 2,
                gap: 2,
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{ fontSize: { md: "1.2rem", xs: "0.8rem" } }}
              >
                CALL FOR ANY KIND OF SERVICE
              </Typography>
              <Typography
                fontWeight="bold"
                color="#fff"
                fontStyle="italic"
                sx={{ fontSize: { md: "1.525rem", xs: "1.125rem" } }}
              >
                +263 712 074 753
              </Typography>
              <Typography
                fontWeight="bold"
                color="#fff"
                fontStyle="italic"
                sx={{ fontSize: { md: "1.525rem", xs: "1.125rem" } }}
              >
                +263 772 960 955
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              top: { md: "25%", xs: "10%" },
              left: "5%",
              gap: 4,
            }}
          >
            <Stack
              sx={{
                boxShadow: "0 2px 4px rgba(0,0,0,0.4)",
                width: { md: "240px", xs: "180px" },
                height: "auto",
                background: "#fff",
                p: 2,
                gap: 1,
              }}
            >
              <Stack sx={{ width: { md: "30px", xs: "20px" } }}>
                <HiUserGroup
                  style={{ color: "#0093d9", width: "100%", height: "100%" }}
                />
              </Stack>
              <Typography variant="h6" fontWeight="bold">
                15 +
              </Typography>
              <Typography
                variant="body"
                fontWeight="bold"
                sx={{ fontSize: { md: "16px", xs: "12px" } }}
              >
                PROFESSIONAL TEAM
              </Typography>
            </Stack>
            <Stack
              sx={{
                boxShadow: "0 2px 4px rgba(0,0,0,0.4)",
                width: { md: "240px", xs: "180px" },
                height: "auto",
                background: "#fff",
                p: 2,
                gap: 1,
              }}
            >
              <Stack sx={{ width: { md: "30px", xs: "20px" } }}>
                <HiUserGroup
                  style={{ color: "#0093d9", width: "100%", height: "100%" }}
                />
              </Stack>
              <Typography variant="h6" fontWeight="bold">
                30 +
              </Typography>
              <Typography
                variant="body"
                fontWeight="bold"
                sx={{ fontSize: { md: "16px", xs: "12px" } }}
              >
                STAISFIED CUSTOMER
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <img
              src={images.hero}
              alt=""
              style={{
                width: "80%",
                position: "absolute",
                top: "30%",
                right: "0",
                zIndex: "-1",
              }}
            />
          </Stack>
        </Stack>
      </Grid>
    </Stack>
  );
}

export default AboutUs;
