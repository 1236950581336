import React from "react";
import "./home.css";
import { Hero } from "../../container";
import images from "../../constants/images";
import Solutions from "../../components/Solutions";
import Service from "../../components/Service";
import Unique from "../../components/Unique";
import Project from "../../components/Project";

function Home() {
  return (
    <>
      <div className="app__home">
        <Hero title="Home" image={images.home} />
        <Solutions />
        <Service />
        <Unique />
        <Project />
      </div>
    </>
  );
}

export default Home;
