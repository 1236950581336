import React from "react";
import "./hero.css";
import { Typography } from "@mui/material";

function Hero({ image, title }) {
  return (
    <div
      className="app__hero"
      style={{
        "&.app__hero::before": {
          backgroundImage: `url(${image})`,
        },
      }}
    >
      <div className="app__hero-content">
        <Typography
          sx={{
            fontSize: { md: "2.125rem", xs: "1.825rem", fontWeight: "bold" },
          }}
        >
          {title}
        </Typography>
        <div />
      </div>
    </div>
  );
}

export default Hero;
