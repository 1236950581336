import React from "react";
import { Stack } from "@mui/material";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

function Layout({ children }) {
  return (
    <Stack>
      <Navbar />
      {children}
      <Footer />
      <ScrollToTop />
    </Stack>
  );
}

export default Layout;
