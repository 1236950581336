import React from "react";
import { Stack } from "@mui/material";

function Grid({ children }) {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: { md: "repeat(2,1fr)", xs: "1fr" },
        gap: 4,
      }}
    >
      {children}
    </Stack>
  );
}

export default Grid;
