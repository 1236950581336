import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import images from "../constants/images";
import Grid from "./Grid";
import { SiCapacitor } from "react-icons/si";
import { HiUserGroup } from "react-icons/hi";
import { BiEqualizer } from "react-icons/bi";

const UniqueCard = ({ icon, title, tooltip }) => {
  return (
    <Stack sx={{ flexDirection: "row" }} gap={3} width="100%">
      <Tooltip title={tooltip}>
        <Stack
          sx={{
            width: "70px",
            height: "70px",
            background: "green",
            color: "#fff",
            borderRadius: "5px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Stack>
      </Tooltip>
      <Stack
        sx={{
          width: "80%",
          height: "auto",
          background: "#0093d9",
          color: "#fff",
          borderRadius: "5px",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="caption">{title}</Typography>
      </Stack>
    </Stack>
  );
};

function Unique() {
  return (
    <Stack sx={{ m: 6 }}>
      <Grid>
        <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
          <div>
            <img src={images.table} alt="" style={{ width: "100%" }} />
          </div>
        </Stack>
        <Stack gap={3}>
          <Typography variant="h6" fontWeight="bold" color="#0093d9">
            What makes us Unique & Competent
          </Typography>
          <Typography variant="body">
            Ancient Engineering is committed to always provide optimum solutions
            no matter how complex the task is. Our staff engage in continuous
            improvement in their various field of expertise to pace up with any
            technological advancement. We benchmark our operations with best
            performing countries regionally and globally.
          </Typography>
          <UniqueCard
            tooltip="Capacity"
            icon={<SiCapacitor style={{ width: "30px", height: "30px" }} />}
            title="Ancient is made up of professionally qualified individuals in
                various fields of expertise."
          />

          <UniqueCard
            tooltip="Team Focus"
            icon={<HiUserGroup style={{ width: "30px", height: "30px" }} />}
            title="Ancient has a strong culture of team work. This makes us very efficient in executing our duties."
          />
          <UniqueCard
            tooltip="Quality"
            icon={<BiEqualizer style={{ width: "30px", height: "30px" }} />}
            title=" Ancient use a lean six sigma management approach which focuses on the excellent customer service by minimization of defects and waste to deliver real value to the customer."
          />
        </Stack>
      </Grid>
    </Stack>
  );
}

export default Unique;
