import React from "react";
import { Hero } from "../../container";
import images from "../../constants/images";
import "./services.css";
import ServiceTitle from "../../components/ServiceTitle";
import ServiceTabs from "../../components/ServiceTabs";
import ServiceShow from "../../components/ServiceShow";

function Services() {
  return (
    <>
      <div className="app__services">
        <Hero image={images.services} title="Services" />
        <ServiceTitle />
        <ServiceTabs />
        <ServiceShow />
      </div>
    </>
  );
}

export default Services;
