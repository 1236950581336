import React from "react";
import { Stack, Typography } from "@mui/material";
import images from "../constants/images";

const Show = ({ imgUrl, title }) => {
  return (
    <Stack sx={{ width: "100%", height: "100%", border: "4px solid #0093d9" }}>
      <Stack
        sx={{
          width: "100%",
          height: "100%",

          objectFit: "contain",
        }}
      >
        <img src={imgUrl} alt="" style={{ width: "100%", height: "300px" }} />
      </Stack>
      <Stack sx={{ background: "#333", color: "#fff", p: 2 }}>
        <Typography>{title}</Typography>
      </Stack>
    </Stack>
  );
};

function ServiceShow() {
  return (
    <Stack sx={{ mx: { md: 8, xs: 4 }, gap: 4, mb: 8 }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" mb={4}>
        Our Projects
      </Typography>
      <Stack
        sx={{
          gap: 4,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(250px,1fr))",
        }}
      >
        <Show imgUrl={images.desk} title="Desk Furniture" />
        <Show imgUrl={images.roof} title="Solar Mounting" />
        <Show imgUrl={images.table} title="Tables And Chairs" />
        <Show imgUrl={images.truck} title="On-Site Delivery" />
        <Show imgUrl={images.anc} title="Electricians" />
        <Show imgUrl={images.anc1} title="Miller Fabrication" />
        <Show imgUrl={images.anc2} title="Man at Work" />
      </Stack>
    </Stack>
  );
}

export default ServiceShow;
