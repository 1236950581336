import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material/";
import Industrial from "./Industrial";
import Green from "./Green";
import Fabrication from "./Fabrication";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ServiceTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "90%", mx: { md: 8, xs: 3 }, my: 4 }}>
      <Box sx={{ borderBottom: 1, borderColor: "#f4f4f4", width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ sx: { background: "#f4f4f4" } }}
          sx={{
            "& button": { borderRadius: "10px  10px  0 0" },
            "& button.Mui-selected": {
              backgroundColor: "#f4f4f4",
              color: "#0093d9",
            },
          }}
        >
          <Tab label="Industrial" {...a11yProps(0)} />
          <Tab label="Green Energy" {...a11yProps(1)} />
          <Tab label="Fabrication" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className=".padding">
        <Industrial />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Green />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Fabrication />
      </TabPanel>
    </Box>
  );
}

export default ServiceTabs;
