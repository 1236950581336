import React from "react";
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { MdExpandMore } from "react-icons/md";

function AboutAccord() {
  return (
    <Stack sx={{ mx: { md: 8, xs: 6 }, gap: 4 }}>
      <Stack gap={2}>
        <Typography fontWeight="bold" variant="body" color="#0093d9">
          OUR GOAL
        </Typography>
        <Typography
          fontWeight="bold"
          fontSize={{ md: "2.125rem", xs: "1.425rem" }}
        >
          COMPANY MISSION
        </Typography>
        <Typography variant="body2">
          To promote value addition in the supply chain while creating
          employment through entrepreneurship, market focused and committed to
          excellence in serving customers.
        </Typography>
      </Stack>
      <Stack>
        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>OUR VISION</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              To provide total exhibition of industrial technology envisaged in
              one innovative and confident engineering company driven by a true
              passion to provide intelligent factory answers.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>OUR VALUES</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack gap={2}>
              <Typography variant="body2">
                Excellent and quick service delivery.
              </Typography>
              <Typography variant="body2">
                Driven by technology and innovation.
              </Typography>
              <Typography variant="body2">
                Integrity and excellent professionalism in all our activities.
              </Typography>
              <Typography variant="body2">
                We believe in long term client partnership and trust.
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Stack>
  );
}

export default AboutAccord;
