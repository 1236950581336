import React from "react";
import { Stack, Typography } from "@mui/material";

function Fabrication() {
  return (
    <Stack sx={{ m: -3, p: 4, background: "#f4f4f4", color: "#000" }}>
      <Typography
        sx={{
          fontSize: { md: "2.125rem", xs: "1.825rem", fontWeight: "bold" },
        }}
      >
        Fabrication
      </Typography>
      <br />
      <p>
        Ancient Engineering forms part of the engineering industry concentrating
        on on-site & off-site development, repair work and maintenance
        contracts. These services are rendered by our experienced team of
        professionals who are well versed in all kinds of infrastructural
        projects. Our services are offer in keeping the end vision and objective
        of the clients in the mind within the stipulated time frame. And when it
        comes to the structural engineering management, the company offers the
        most feasible and economically viable solutions.
      </p>
      <p>We have segmented our main objects into categories as follows:</p>
      <ul>
        <li>
          Pipe Fabrication/Installation and Steel Erection (Workshop Component
          Manufacture and Site Fabrication and Installation).
        </li>
        <li>
          Rotating equipment (Pumps, Motors) overhaul, management of change,
          selection of new equipment, service/ manufacture of spare parts.
        </li>
        <li>Laser alignment.</li>
        <li>Recruitment, Selection, Placements and Outsourcing.</li>
        <li>Condition Monitoring.</li>
      </ul>
    </Stack>
  );
}

export default Fabrication;
